<template>
  <v-stepper v-model="actionParams.wizardStep">
    <v-stepper-header>
      <v-stepper-step :complete="actionParams.wizardStep > 1" step="1">
        {{ $t("STEP") }} 1
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 2" step="2">
        {{ $t("STEP") }} 2
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="actionParams.wizardStep > 3" step="3">
        {{ $t("STEP") }} 3
      </v-stepper-step>
      <v-divider></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <StepStockActionSelectStore
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :items.sync="items"
          :selectedStore.sync="selectedStore"
        >
        </StepStockActionSelectStore>
      </v-stepper-content>
      <v-stepper-content step="2">
        <StepStockActionSetItems
          :firstLoader.sync="tempFirstLoader"
          :wizardStep.sync="actionParams.wizardStep"
          :globalInternalDoc.sync="globalInternalDoc"
          :testingReasons="testingReasons"
          :scrappingTypes="scrappingTypes"
          :notes.sync="notes"
          :items.sync="items"
          :headers="headers"
          :selectedItems="selectedItems"
          :selectedItemsTotal="selectedItemsTotal"
          :store="selectedStore"
          :stockActionTypeId="stockActionTypeId"
          :showTotalPrice="false"
        >
        </StepStockActionSetItems>
      </v-stepper-content>
      <v-stepper-content step="3">
        <StepStockActionPreview
          :firstLoader.sync="tempFirstLoader"
          :doGetItems.sync="doGetItems"
          :wizardStep.sync="actionParams.wizardStep"
          :globalInternalDoc.sync="globalInternalDoc"
          :testingReasons="testingReasons"
          :scrappingTypes="scrappingTypes"
          :notes.sync="notes"
          :selectedItems="selectedItems"
          :headers="headers"
          :selectedItemsTotal="selectedItemsTotal"
          :selectedStore.sync="selectedStore"
          :stockActionTypeId="stockActionTypeId"
          :showTotalPrice="false"
        >
        </StepStockActionPreview>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>
import { FETCH_SELECTS_BASIC } from "@/core/services/store/shared_base.module";
import StepStockActionSelectStore from "@/modules/stock/components/actions/stockScrapping/StepStockActionSelectStore.vue";
import StepStockActionSetItems from "@/modules/stock/components/actions/StepStockActionSetItems.vue";
import StepStockActionPreview from "@/modules/stock/components/actions/StepStockActionPreview.vue";

import _ from "lodash";
export default {
  name: "StockTestingStepper",
  inheritAttrs: false,
  props: {
    firstLoader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      stockActionTypeId: 6,
      doGetItems: true,
      actionParams: {
        wizardStep: 1
      },
      globalInternalDoc: {
        visible: true,
        num: "",
        date: "",
        totalPrice: "0.00"
      },
      globalTesting: {
        stockTestingReasonId: null
      },
      notes: "",
      items: [],
      testingReasons: [],
      selectedStore: {},
      headers: [
        {
          text: this.$t("CODE"),
          align: "start",
          filterable: true,
          value: "code"
        },
        {
          text: this.$t("ITEM"),
          align: "start",
          filterable: true,
          value: "name"
        },
        {
          text: this.$t("TESTING"),
          value: "stockActionItem.unitValue",
          sortable: true
        },
        {
          text: this.$t("QUANTITY"),
          value: "stockActionItem.sumLastStockActionsUnitValue",
          sortable: true
        },
        {
          text: this.$t("LOT"),
          value: "stockActionItem.stockLot",
          sortable: true
        },
        {
          text: this.$t("LOT_EXP_DATE"),
          value: "stockActionItem.stockLotExpDate",
          sortable: true
        },
        {
          text: this.$t("TESTING_REASON"),
          value: "testing.stockTestingReasonId",
          sortable: true,
          width: "50%"
        }
      ]
    };
  },
  components: {
    StepStockActionSelectStore,
    StepStockActionSetItems,
    StepStockActionPreview
  },
  mounted() {
    let vm = this;
    vm.getSelects();
  },
  computed: {
    tempFirstLoader: {
      get: function() {
        return this.firstLoader;
      },
      set: function(newValue) {
        this.$emit("update:firstLoader", newValue);
      }
    },
    selectedItems() {
      let vm = this;
      let selectedItems = [];
      if (_.size(vm.items) > 0) {
        vm.items.forEach(category => {
          let filteredItems = category.items.filter(function(item) {
            return item.stockActionItem.unitValue > 0;
          });
          selectedItems = [...selectedItems, ...filteredItems];
        });
      }
      return selectedItems;
    },
    selectedItemsTotal() {
      let vm = this;
      let totalPrice = 0;
      vm.selectedItems.forEach(item => {
        let itemTotalPrice = item.stockActionItem.totalPrice;
        // let transportPrice = item.stockActionItem.transportPrice;
        totalPrice += parseFloat(itemTotalPrice);
      });
      return this.$options.filters.formatDecimal6(totalPrice);
    }
  },
  watch: {
    selectedItems: {
      handler: function(newValue, oldValue) {
        this.globalInternalDoc.totalPrice = newValue
          .reduce(
            (sum, item) => sum + parseFloat(item.stockActionItem.totalPrice),
            0
          )
          .toFixed(6);
      },
      deep: true
    }
  },
  methods: {
    getSelects() {
      let vm = this;
      let payload = {
        testing_reasons: {
          fields: ["id", "name"],
          search: ""
        }
      };
      this.$store
        .dispatch(FETCH_SELECTS_BASIC, payload)
        .then(data => {
          vm.testingReasons = data.data.testing_reasons;
        })
        .catch(response => {
          console.log(response);
        });
    },
    updateWizardStep(step) {
      let vm = this;
      vm.actionParams.wizardStep = step;
    }
  }
};
</script>
